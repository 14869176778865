import axios from "axios";

export const getArticles = () => {
	return axios.get(
		`${process.env.REACT_APP_API_ENDPOINT}/news/articoli/amministrazione`
	);
};

export const getArticle = id => {
	return axios.get(
		`${process.env.REACT_APP_API_ENDPOINT}/news/articolo?id=${id}`
	);
};

export const publishArticle = async (id, category, payload) => {
	console.log(payload)
	return axios.put(
		`${process.env.REACT_APP_API_ENDPOINT}/news/articoli/${category}?id=${id}`,
		payload
	);
};

export const publishDraftArticle = async (payload, category) => {
	return axios.post(
		`${process.env.REACT_APP_API_ENDPOINT}/news/articoli/${category}`,
		payload
	);
};

export const editArticle = async (id, payload, category) => {
	return axios.put(
		`${process.env.REACT_APP_API_ENDPOINT}/news/articoli/${category}?id=${id}`,
		payload
	);
};

export const featureArticle = async (id, category, value) => {
	return axios.put(
		`${process.env.REACT_APP_API_ENDPOINT}/news/articolo/inevidenza/${category}?id=${id}`,
		{ isPinned: value }
	);
};
