import React from "react";
import { Link } from "react-router-dom";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Container from "@material-ui/core/Container";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles(theme => ({
	progress: {
		minWidth: 300,
		width: "100%",
		height: "100%",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	back: {
		textDecoration: "none",
	},
}));

const styles = theme => ({
	root: {
		margin: 0,
		minWidth: 200,
		minHeight: 200,
	},
	closeButton: {
		position: "absolute",
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
});

const DialogContent = withStyles(theme => ({
	root: {
		padding: theme.spacing(2),
	},
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
	root: {
		margin: 0,
		padding: theme.spacing(1),
	},
}))(MuiDialogActions);

const DialogTitle = withStyles(styles)(props => {
	const { children, classes, onClose, ...other } = props;
	return (
		<MuiDialogTitle
			disableTypography
			className={classes.dialogTitleRoot}
			{...other}
		>
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton
					aria-label="close"
					className={classes.closeButton}
					onClick={onClose}
				>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});

const FeedbackDialog = ({
	title = "Esito",
	open,
	loading,
	onClose,
	successMessage,
	errorMessage,
	success,
	pathTo,
	showFooter = true
}) => {
	const classes = useStyles();
	return (
		<Dialog onClose={onClose} aria-labelledby="result-dialog" open={open}>
			<DialogTitle id="result-dialog" onClose={onClose}>
				{title}
			</DialogTitle>
			<DialogContent dividers>
				<Container classes={{ root: classes.progress }}>
					{loading ? (
						<CircularProgress />
					) : (
						<span>{success ? successMessage : errorMessage}</span>
					)}
				</Container>
			</DialogContent>
			{showFooter &&
				<DialogActions>
					<Link to={pathTo} className={classes.back}>
						<Button autoFocus color="primary" disabled={loading}>
							Torna agli articoli
						</Button>
					</Link>
				</DialogActions>
			}

		</Dialog>
	);
};

export default FeedbackDialog;
