import React, { useState, useRef, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { getSession } from "react-session-persist";
import {
	Backdrop,
	Checkbox,
	CircularProgress,
	FormControlLabel,
	Tooltip,
	Typography,
} from "@material-ui/core";
import { DateRange } from "react-date-range";
import {
	getArticle,
	publishArticle,
	publishDraftArticle,
	editArticle,
	featureArticle,
} from "../../../services/Articoli";
import { getAttachment, uploadAttachment } from "../../../services/Allegati";
import { getBase64 } from "../../../utils";
import { blue, purple, red } from "@material-ui/core/colors";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import RemoveIcon from "@material-ui/icons/Delete";
import Container from "@material-ui/core/Container";
import JoditEditor from "jodit-react";
import Box from "../../../components/Box";
import TextField from "@material-ui/core/TextField";
import FeedbackDialog from "../../../components/FeedbackDialog";
import UploadButton from "../../../components/UploadButton";
import Map from "../../../components/Map";
import Search from "../../../components/GoogleMapSearch";
import it from "date-fns/locale/it";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { ArrowBack } from "@material-ui/icons";
import Resizer from "react-image-file-resizer";
//import { ToastProvider, useToast } from "../../../context/Toast"; Funzionante. Da inserire in una versione successiva.
import RichTextEditor from 'react-rte';
const useStyles = makeStyles(theme => ({
	container: {
		display: "flex",
		justifyContent: "space-between",
	},
	header: {
		width: "70%",
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		fontSize: "1rem",
		fontWeight: 500,
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
	},
	header__buttons: {
		width: "50%",
		display: "flex",
		justifyContent: "flex-end",
		"& button": {
			marginLeft: theme.spacing(2),
		},
	},
	title: {
		display: "flex",
		alignItems: "center",
	},
	evidenza: {
		display: "flex",
		flexDirection: "column",
		marginTop: theme.spacing(2),
	},
	removePublish: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	mainBox: {
		width: "70%",
		"& strong": {
			color: theme.palette.grey[700],
		},
	},
	editor: {
		width: "70%",
	},
	mainImage: {
		width: 300,
		height: 300,
	},
	side: {
		display: "flex",
		flexDirection: "column",
		width: "25%",
	},
	removeButton: {
		marginTop: theme.spacing(1),
	},
	formControl: {
		minWidth: 120,
	},
	calendar: {
		width: "100%",
	},
	search: {
		marginBottom: theme.spacing(2),
	},
	backIcon: {
		textDecoration: "none",
		color: theme.palette.grey[800],
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: "#fff",
	},
}));

export default function NuovoArticolo() {
	const { id } = useParams();
	//  { fireToast } = useToast(); Funzionante. Da inserire in una versione successiva.
	const editor = useRef(null);
	const classes = useStyles();
	const session = getSession();
	const permissions = useSelector(state => state.permissions);
	const [reload, setReload] = useState(true);
	const [title, setTitle] = useState("");
	const [subtitle, setSubTitle] = useState("");
	const [checked, setChecked] = useState(false);
	const [mainImage, setMainImage] = useState();
	const [file, setFile] = useState();
	const [open, setOpen] = React.useState(false);
	const [category, setCategory] = useState("");
	const [published, setPublished] = useState(false);
	const [successMessage, setSuccessMessage] = useState("");
	const [errorMessage, setErrorMessage] = useState("");
	const [isPublishing, setIsPublishing] = useState();
	const [result, setResult] = useState();
	const [loading, setLoading] = useState(false);
	const [dateRange, setDateRange] = useState([
		{
			startDate: new Date(),
			endDate: new Date(),
			key: "selection",
		},
	]);
	const [rteValue, setRteValue] = useState(RichTextEditor.createEmptyValue())

	const config = {
		readonly: false,
		autofocus: false,
		disablePlugins: "font",
		uploader: {
			insertImageAsBase64URI: true,
			url: `${process.env.REACT_APP_API_ENDPOINT}/news/allegati`,
			headers: {
				Authorization: `Bearer ${session.token}`,
			},
		},
		filebrowser: {
			ajax: {
				url: `${process.env.REACT_APP_API_ENDPOINT}/news/allegati`,
				headers: {
					Authorization: `Bearer ${session.token}`,
				},
			},
			uploader: {
				url: `${process.env.REACT_APP_API_ENDPOINT}/news/allegati`,
				headers: {
					Authorization: `Bearer ${session.token}`,
				},
			},
		},
		buttons: [
			"source",
			"|",
			"bold",
			"strikethrough",
			"underline",
			"italic",
			"eraser",
			"|",
			"superscript",
			"subscript",
			"|",
			"ul",
			"ol",
			"|",
			"outdent",
			"indent",
			"|",
			"fontsize",
			"brush",
			"paragraph",
			"|",
			"image",
			"file",
			"video",
			"table",
			"link",
			"|",
			"align",
			"undo",
			"redo",
			"\n",
			"selectall",
			"cut",
			"copy",
			"paste",
			"copyformat",
			"|",
			"hr",
			"symbol",
			"fullsize",
			"print",
			"preview",
			"find",
			"about",
		],
		height: 400,
	};

	useEffect(() => {
		(async () => {
			if (reload) {
				let response;
				if (id) {
					setLoading(true);
					try {
						response = await getArticle(id);
						setRteValue(RichTextEditor.createValueFromString(response.data.testo, 'html'))
						console.log( "TEST", response.data.testo)
						setTitle(response.data.titolo);
						setSubTitle(response.data.descrizione);
						setCategory(response.data.categoria);
						setPublished(response.data.isPubblicato);
						setChecked(response.data.isPinned);
						if (response.data.categoria === "eventi") {
							setDateRange([
								{
									startDate: new Date(response.data.dataInizio),
									endDate: new Date(response.data.dataFine),
									key: "selection",
								},
							]);
						}
					} catch (err) {
						console.error("error on getArticle", err);
						setLoading(false);
					}
					if (response && response.data) {
						try {
							const image = await getAttachment(response.data.immagine);
							setMainImage({
								uri: URL.createObjectURL(image.data),
								image: response.data.immagine,
							});
							setLoading(false);
						} catch (err) {
							console.error("error on getAttachment", err);
							setLoading(false);
						}
					}

					setReload(false);
				}
			}

		})();
	}, [id, reload]);

	const resizeFile = file =>
		new Promise(resolve => {
			Resizer.imageFileResizer(
				file,
				1200,
				1200,
				"JPEG",
				75,
				0,
				uri => {
					resolve(uri);
				},
				"blob"
			);
		});

	const PurpleButton = withStyles(theme => ({
		root: {
			color: theme.palette.getContrastText(purple[400]),
			backgroundColor: purple[600],
			"&:hover": {
				backgroundColor: purple[800],
			},
		},
	}))(Button);

	const BlueButton = withStyles(theme => ({
		root: {
			color: theme.palette.common.white,
			backgroundColor: blue[700],
			"&:hover": {
				backgroundColor: blue[900],
			},
		},
	}))(Button);

	const RedButton = withStyles(theme => ({
		root: {
			color: theme.palette.common.white,
			backgroundColor: red[700],
			"&:hover": {
				backgroundColor: red[900],
			},
		},
	}))(Button);
	const AzureButton = withStyles(theme => ({
		root: {
			color: theme.palette.common.white,
			backgroundColor: "#00AEDB",
			"&:hover": {
				backgroundColor: "#009cc5",
			},
			display: "inline-block"
		},
	}))(Button);
	const RemoveButton = withStyles(theme => ({
		root: {
			color: theme.palette.getContrastText(red[400]),
			backgroundColor: red[400],
			"&:hover": {
				backgroundColor: red[600],
			},
		},
	}))(Button);

	const hasPermission = () =>
		permissions.find(permission => permission.sezione === "articoli")
			.articoli === 0;

	const handleClose = () => {
		setOpen(false);
		setSuccessMessage("")
		setErrorMessage("")
	};

	const handleTitle = event => {
		setTitle(event.target.value);
	};

	const handleSubTitle = event => {
		setSubTitle(event.target.value);
	};

	const handleEvidenza = async event => {
		setChecked(event.target.checked);
		if (id) {
			setOpen(true)
			try {
				await featureArticle(id, category, event.target.checked);
				setSuccessMessage("l'articolo è stato messo in evidenza")
			} catch (err) {
				console.error("error on featureArticle")
				setChecked(!event.target.checked);
				setErrorMessage(err.response.data.message)
			}
		}
	};

	const handleRemovePublishing = async () => {
		try {
			await publishArticle(id, category, {
				isPubblicato: false,
			});
			setPublished(false);
			setReload(true)
		} catch (err) {
			console.error("error on publishArticle", err);
		}
	};

	const handleAddMainImage = async event => {
		const fileToResize = event.target.files[0];
		const imageBlob = await resizeFile(fileToResize);
		let fileResize = new File([imageBlob], fileToResize.name, {
			type: "image/jpeg",
		});
		getBase64(fileResize, fileResize => {
			setMainImage({ uri: fileResize });
		});
		setFile(fileResize);

		/*
	//ORIGINALE OMAR
	setFile(event.target.files[0]);
	getBase64(event.target.files[0], file => {
	  setMainImage(file);
	});
*/
	};

	const handleDelete = () => {
		setMainImage(null);
	};

	const handleSaveDraft = async () => {
		setOpen(true);
		setIsPublishing(true);
		let image;
		try {
			const fd = new FormData();
			fd.append("allegati", file);
			image = await uploadAttachment(fd, category);
		} catch (err) {
			console.error("error on uploadAttachment", err);
			setErrorMessage(
				"Errore nel caricamento dell'immagine in evidenza. Riprovare più tardi."
			);
			setIsPublishing(true);
			throw new Error("Error on uploadAttachment");
		}

		const payload = {
			titolo: title,
			descrizione: subtitle,
			testo: rteValue.toString('html'),
			categoria: category,
			dataInizio: category === "eventi" ? dateRange[0].startDate : null,
			dataFine: category === "eventi" ? dateRange[0].endDate : null,
			immagine: image.data[0],
			isPinned: checked,
			isPubblicato: false,
			posizione: [0, 0],
			allegati: [],
		};

		try {
			const response = await publishDraftArticle(payload, category);
			const data = await response.data;
			setSuccessMessage("La bozza è stata salvata con successo.");
			setIsPublishing(false);
			setResult(data);

			setReload(true)
		} catch (err) {
			console.error("error on publishDraftArticle", err);
			setErrorMessage(
				"Si è verificato un errore nel salvataggio della bozza. Riprovare più tardi."
			);
			setIsPublishing(false);
		}
	};

	const handleEditDraft = async () => {
		console.log("EDIT D")
		setOpen(true);
		setIsPublishing(true);
		setPublished(false);
		let image = mainImage.image;
		if (file) {
			// se c'è file settato, vuol dire che è stata inserita una nuova immagine
			try {
				const fd = new FormData();
				fd.append("allegati", file);
				const imageResponse = await uploadAttachment(fd, category);
				image = imageResponse.data
			} catch (err) {
				console.error("error on uploadAttachment", err);
				setErrorMessage(
					"Errore nel caricamento dell'immagine in evidenza. Riprovare più tardi."
				);
				setIsPublishing(true);
				throw new Error("Error on uploadAttachment");
			}
		}

		const payload = {
			titolo: title,
			descrizione: subtitle,
			testo: rteValue.toString('html'),
			categoria: category,
			dataInizio: category === "eventi" ? dateRange[0].startDate : null,
			dataFine: category === "eventi" ? dateRange[0].endDate : null,
			immagine: image,
			isPinned: checked,
			isPubblicato: false,
			posizione: [0, 0],
			allegati: [],
		};

		try {
			const response = await editArticle(id, payload, category);
			const data = await response.data;
			setIsPublishing(false);
			setResult(data);
			setSuccessMessage("L'articolo è rimosso dalla pubblicazione e salvato in bozza");

			setReload(true)
			//fireToast("L'articolo è stato modificato con successo"); Funzionante. Da inserire in una versione successiva dell'app.
		} catch (err) {
			console.error("error on editArticle", err);
			setErrorMessage(
				"Errore nella modifica dell'articolo. Riprovare più tardi."
			);
			setIsPublishing(false);
		}
	};
	const handlePublishAndNotification = async () => {
		try {
			await handlePublish(null, true)
		} catch (e) {
			setErrorMessage(
				e.message
			);
			throw new Error("error on publishArticle");
		}

	}

	const handlePublish = async (event, notification = false) => {
		setOpen(true);
		setIsPublishing(true);
		console.log("qui")
		const fd = new FormData();
		let image;

		if (!id) {
			fd.append("allegati", file);
			try {
				image = await uploadAttachment(fd, category);
				console.log("image", image);
			} catch (err) {
				console.error("error on uploadAttachment", err);
				setErrorMessage(
					"Errore nel caricamento dell'immagine in evidenza. Riprovare più tardi."
				);
				throw new Error("error on uploadAttachment");
			}

			const payload = {
				titolo: title,
				descrizione: subtitle,
				testo: rteValue.toString('html'),
				categoria: category,
				dataInizio: category === "eventi" ? dateRange[0].startDate : null,
				dataFine: category === "eventi" ? dateRange[0].endDate : null,
				immagine: image.data[0],
				isPinned: checked,
				isPubblicato: false,
				posizione: [0, 0],
				allegati: [],
			};

			let response;

			try {
				response = await publishDraftArticle(payload, category);
				console.log("response", response);
			} catch (err) {
				console.error("error on publishDraftArticle", err);
				setErrorMessage(
					"Errore nel salvataggio della bozza. Riprovare più tardi."
				);
				setIsPublishing(false);
				throw new Error("error on publishDraftArticle");
			}

			try {
				await publishArticle(response.data._id, category, {
					isPubblicato: true,
					isNotificaPush: notification
				});
				setIsPublishing(false);
				setResult(true);
				setPublished(true);
				setSuccessMessage("Articolo pubblicato con successo")
			} catch (err) {
				console.error("error on publishArticle", err);
				setErrorMessage(
					"Errore nella pubblicazione dell'articolo. Riprovare più tardi."
				);
				setIsPublishing(false);
				throw new Error("error on publishArticle");
			}
		} else {
			try {
				await publishArticle(id, category, {
					isPubblicato: true,
					isNotificaPush: notification
				});
				setTimeout(() => {
					setIsPublishing(false);
					setResult(true);
					setPublished(true);
					setSuccessMessage("Articolo pubblicato con successo")
				}, 2000);
			} catch (err) {
				console.error("error on publishArticle", err);
				setErrorMessage(
					"Errore nella modifica dell'articolo. Riprovare più tardi."
				);
				throw new Error("error on publishArticle");
			}
		}
	};
	console.log("RTE", rteValue.toString("html"))

	return (
		<Container maxWidth="xl">
			<Link to="/articoli" className={classes.backIcon}>
				<Tooltip
					title="Torna indietro"
					aria-label="Torna indietro"
					placement="right"
				>
					<ArrowBack />
				</Tooltip>
			</Link>
			<div className={classes.header}>
				<Typography variant="h4">
					{id ? "Modifica articolo" : "Crea nuovo articolo"}
				</Typography>
				<div className={classes.header__buttons}>
					{!id ? (
						<BlueButton
							variant="contained"
							onClick={handleSaveDraft}
							disabled={
								!title.length || !category || !mainImage || !subtitle.length
							}
						>
							Salva bozza
						</BlueButton>
					) : null}
					{id ? (
						<PurpleButton
							variant="contained"
							disabled={
								!title.length || !category || !mainImage || !subtitle.length
							}
							onClick={handleEditDraft}
						>
							{published ? "Salva bozza" : "Salva"}
						</PurpleButton>
					) : null}
					{hasPermission() ? (
						<div>
							<RedButton
								variant="contained"
								disabled={
									published ||
									!title.length ||
									!category ||
									!mainImage ||
									!subtitle.length
								}
								onClick={handlePublish}
							>
								Pubblica
							</RedButton>
							<AzureButton
								variant="contained"
								disabled={
									published ||
									!title.length ||
									!category ||
									!mainImage ||
									!subtitle.length
								}
								onClick={handlePublishAndNotification}
							>
								Pubblica con Notifica Push
							</AzureButton>
						</div>
					) : null}
				</div>
			</div>
			<div className={classes.container}>
				<div className={classes.mainBox}>
					<Box padding={15}>
						<TextField
							id="title"
							label="Titolo"
							type="text"
							variant="outlined"
							fullWidth
							value={title}
							onChange={handleTitle}
						/>
						<TextField
							id="subtitle"
							label="Sottotitolo"
							type="text"
							variant="outlined"
							fullWidth
							value={subtitle}
							style={{ marginTop: 10 }}
							onChange={handleSubTitle}
						/>

						{published && id ? (
							<div className={classes.evidenza}>
								<strong>Articolo in evidenza</strong>
								<FormControlLabel
									control={
										<Checkbox
											checked={checked}
											onChange={handleEvidenza}
											name="evidenza"
											color="primary"
										/>
									}
									label="In evidenza"
								/>
							</div>
						) : null}

						<div style={{ marginTop: 10 }}>
							<strong>Descrizione progetto</strong>
							<div style={{ marginTop: 10 }}>
								<RichTextEditor
									value={rteValue}
									onChange={(value) => {
										setRteValue(value)
									}}
								/>
							</div>
						</div>
					</Box>
				</div>
				<div className={classes.side}>
					{published ? (
						<Box padding={15}>
							<div className={classes.removePublish}>
								<BlueButton
									variant="contained"
									onClick={handleRemovePublishing}
								>
									Rimuovi dalla pubblicazione
								</BlueButton>
							</div>
						</Box>
					) : null}
					<Box padding={15} internalTitle="Immagine in evidenza" spaced>
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								flexDirection: "column",
							}}
						>
							{!mainImage ? (
								<UploadButton onUpload={handleAddMainImage} />
							) : (
								<>
									<img
										src={mainImage.uri}
										className={classes.mainImage}
										alt="Immagine in evidenza"
									/>
									<RemoveButton
										variant="contained"
										startIcon={<RemoveIcon />}
										className={classes.removeButton}
										onClick={handleDelete}
									>
										Rimuovi
									</RemoveButton>
								</>
							)}
						</div>
					</Box>
					<Box padding={15} internalTitle="Categoria articolo" spaced>
						<FormControl
							variant="outlined"
							className={classes.formControl}
							fullWidth
						>
							<InputLabel id="select-category">Categoria</InputLabel>
							<Select
								labelId="select-category"
								id="category"
								value={category}
								onChange={event => setCategory(event.target.value)}
								label="Categoria"
								placeholder="Categoria"
								required
							>
								<MenuItem value="news">News</MenuItem>
								<MenuItem value="eventi">Evento</MenuItem>
								<MenuItem value="turismo">Turismo</MenuItem>
								<MenuItem value="utilita">Utilità</MenuItem>
							</Select>
						</FormControl>
					</Box>
					{category === "eventi" ? (
						<Box padding={15} internalTitle="Durata progetto" spaced>
							<DateRange
								className={classes.calendar}
								editableDateInputs={false}
								onChange={item => setDateRange([item.selection])}
								moveRangeOnFirstSelection={false}
								ranges={dateRange}
								lang="it"
								locale={it}
							/>
						</Box>
					) : null}
					{category === "turismo" && false ? ( //TODO: rimuovere "false" e aggiungere Places alla ricerca
						<Box padding={15} internalTitle="Posizione" spaced>
							<div className={classes.search}>
								<Search label="Cerca location" />
							</div>
							<Map marker={true} />
						</Box>
					) : null}
				</div>
			</div>
			<FeedbackDialog
				title={errorMessage ? "Errore" : "Esito"}
				open={open}
				loading={isPublishing}
				success={result}
				onClose={handleClose}
				pathTo="/articoli"
				successMessage={successMessage}
				errorMessage={errorMessage}
				showFooter={false}
			/>
			<Backdrop
				className={classes.backdrop}
				open={loading}
				onClick={() => setOpen(false)}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
		</Container>
	);
}
