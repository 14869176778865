import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { getRequest, getRequestAttachment } from "../../../services/Baratto";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "../../../components/Dialog";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Carousel from "../../../components/Carousel";
import moment from "moment";

const useStyles = makeStyles(theme => ({
	progress: {
		width: "100%",
		height: "100%",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	header: {
		display: "flex",
		justifyContent: "space-between",
	},
	actionsContainer: {
		display: "flex",
	},
	actionIcons: {
		padding: "10px",
		borderRadius: "50%",
		background: "#fff",
		marginRight: 10,
		display: "flex",
		boxShadow: "0px 0px 3px 0px rgba(0,0,0,0.15)",
		cursor: "pointer",
	},
	hover: {
		background: "#fff",
		"&:hover": {
			background: "#ccc",
		},
	},
	actionsTitle: {
		marginBottom: 5,
	},
	category: {
		marginLeft: "10px",
		padding: "5px",
		color: "#fff",
		backgroundColor: "orange",
		borderRadius: "5px",
		width: "contain",
	},
	subCategory: {
		marginLeft: "10px",
		padding: "5px",
		color: "#fff",
		backgroundColor: "green",
		borderRadius: "5px",
		width: "contain",
	},
	title: {
		display: "flex",
		alignItems: "center",
	},
	carousel: {
		width: "100%",
		display: "flex",
	},
	image: {
		height: "400px",
		width: "400px",
		backgroundColor: "#ccc",
		margin: "10px 10px",
	},
	state: {
		padding: "10px 0",
		fontSize: "15px",
	},
	disabled: {
		background: "#999",
		pointerEvents: "none",
		opacity: 0.5,
	},
	backIcon: {
		textDecoration: "none",
		color: theme.palette.grey[800],
	},
	dialogContainer: {
		display: "flex",
		flexDirection: "column",
	},
	formControl: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(2),
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
		minWidth: 300,
	},
	selectTitle: {
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
	},
	textArea: {
		marginTop: theme.spacing(1),
		padding: theme.spacing(2),
		minWidth: 500,
	},
	descriptionContainer: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
}));

export default function Dettaglio() {
	const classes = useStyles();
	let { id } = useParams();
	const [request, setRequest] = useState();
	const [attachments, setAttachments] = useState();
	const [open, setOpen] = useState(false);
	const [closureState, setClosureState] = useState(4);
	const [note, setNote] = useState("");

	useEffect(() => {
		const getRequestById = async () => {
			const { data } = await getRequest(id);
			const attachments = await Promise.all(
				data.allegati.map(attachment =>
					getRequestAttachment(attachment)
				)
			);
			setRequest(data);
			setAttachments(
				attachments.map(img => {
					const obj = {
						id: Math.random().toString(36),
						url: URL.createObjectURL(img.data),
					};
					return obj;
				})
			);
		};
		getRequestById();
	}, [id]);

	const handleCloseDialog = () => setOpen(false);
	const handleChangeClosureState = event => setClosureState(event.target.value);
	const handleChangeNote = event => setNote(event.target.value);

	if (request && attachments) {
		const {
			anagrafica,
			dichiarazioni,
			isPersonaGiuridica,
			iseeDichiarato,
		} = request;
		return (
			<Container maxWidth="xl">
				<Link to="/partenariato" className={classes.backIcon}>
					<ArrowBackIcon />
				</Link>
				<div className={classes.descriptionContainer}>
					<h2>
						Dati Richiedente{" "}
						<span>
							{" "}
							{isPersonaGiuridica === false
								? "(Persona fisica)"
								: "(Persona giuridica)"}{" "}
						</span>
					</h2>
					<p>
						<span style={{ fontWeight: "bold" }}>Ragione Sociale: </span>{" "}
						{anagrafica ? anagrafica.cognome : ""}{" "}
						{anagrafica ? anagrafica.nome : ""}
					</p>
					<p>
						<span style={{ fontWeight: "bold" }}>
							Luogo e data di nascita:{" "}
						</span>{" "}
						{anagrafica ? anagrafica.luogoDiNascita : ""} ,{" "}
						{anagrafica
							? moment(anagrafica.dataDiNascita).format("DD/MM/YYYY")
							: ""}
					</p>
					<p>
						<span style={{ fontWeight: "bold" }}>Codice Fiscale: </span>{" "}
						{anagrafica ? anagrafica.cf : ""}
					</p>
					<p>
						<span style={{ fontWeight: "bold" }}>Residenza: </span>{" "}
						{anagrafica ? anagrafica.residenza : ""}
					</p>
					<p>
						<span style={{ fontWeight: "bold" }}>Telefono e email: </span>{" "}
						{anagrafica ? anagrafica.telefono : ""} -{" "}
						{anagrafica ? anagrafica.email : ""}
					</p>
				</div>
				<div style={{ marginTop: 50 }} className={classes.descriptionContainer}>
					<h2>Dichiarazioni del Richiedente</h2>
					<p>
						<span style={{ fontWeight: "bold" }}>
							Di essere residente nel Comune di {process.env.REACT_APP_NOME_COMUNE}:{" "}
						</span>{" "}
						{dichiarazioni.residenza === true ? "Si" : "No"}
					</p>
					<p>
						<span style={{ fontWeight: "bold" }}>
							Di essere intestatario di tutti i tributi comunali:{" "}
						</span>{" "}
						{dichiarazioni.intestazioneTributi === true ? "Si" : "No"}
					</p>
					<p>
						<span style={{ fontWeight: "bold" }}>
							Di avere un età compresa tra i 18 e i 65 anni:{" "}
						</span>{" "}
						{dichiarazioni.eta === true ? "Si" : "No"}
					</p>
					<p>
						<span style={{ fontWeight: "bold" }}>
							Di essere in possesso dell'idoneità psico-fisica in relazione alle
							caratteristiche dell'attività o del servizio da svolgere:{" "}
						</span>{" "}
						{dichiarazioni.idoneitaPsicoFisica === true ? "Si" : "No"}
					</p>
					<p>
						<span style={{ fontWeight: "bold" }}>
							Di richiedere l'agevolazione per i tributi comunale:{" "}
						</span>{" "}
						{dichiarazioni.richiestaAgevolazione === true ? "Si" : "No"}
					</p>
					<p>
						<span style={{ fontWeight: "bold" }}>
							Membri maggiorenni della famiglia in stato di disoccupazione e in
							assenza di forme di integrazione e sostegno al reddito:{" "}
						</span>{" "}
						{dichiarazioni.maggiorenniDisoccupati !== ""
							? dichiarazioni.maggiorenniDisoccupati
							: "0"}
					</p>
					<p>
						<span style={{ fontWeight: "bold" }}>
							Membri maggiorenni della famiglia in cassa integrazione:{" "}
						</span>{" "}
						{dichiarazioni.maggiorenniCassaIntegrazione !== ""
							? dichiarazioni.maggiorenniCassaIntegrazione
							: "0"}
					</p>
					<p>
						<span style={{ fontWeight: "bold" }}>
							Di costituire nucleo mono genitoriale con minori a carico:{" "}
						</span>{" "}
						{dichiarazioni.monoNucleo === true ? "Si" : "No"}
					</p>
					<p>
						<span style={{ fontWeight: "bold" }}>
							Di avere un nucleo familiare con 3 o più figli minori a carico:{" "}
						</span>{" "}
						{dichiarazioni.piuDueMinori === true ? "Si" : "No"}
					</p>
				</div>
				<div style={{ marginTop: 50 }} className={classes.descriptionContainer}>
					<h2>Isee</h2>
					<p>
						<span style={{ fontWeight: "bold" }}>
							Isee dichiarato dal richiedente:{" "}
						</span>{" "}
						{iseeDichiarato !== "" ? iseeDichiarato : ""}
					</p>
				</div>
				<div style={{ marginTop: 50 }} className={classes.descriptionContainer}>
					<h2>Allegati</h2>
					<div className={classes.carousel}>
						{attachments && attachments.length ? (
							<Carousel data={attachments} />
						) : (
							<strong style={{ marginTop: 20, marginBottom: 20 }}>
								Nessun allegato.
							</strong>
						)}
					</div>
				</div>
				<Dialog
					open={open}
					onClose={handleCloseDialog}
					title="Chiusura richiesta"
					buttonTitle="Salva"
				>
					<div className={classes.dialogContainer}>
						<strong className={classes.selectTitle}>
							Selezionare esito chiusura.
						</strong>
						<FormControl className={classes.formControl}>
							<InputLabel id="closure-state">Stato</InputLabel>
							<Select
								labelId="closure-state"
								id="closure"
								value={closureState}
								onChange={handleChangeClosureState}
							>
								<MenuItem value={4}>SUCCESSO</MenuItem>
								<MenuItem value={5}>INSUCCESSO</MenuItem>
								<MenuItem value={7}>GIA' RIPARATO</MenuItem>
								<MenuItem value={8}>NON DI COMPETENZA</MenuItem>
							</Select>
						</FormControl>
						<FormControl className={classes.formControl}>
							<strong>Nota (opzionale)</strong>
							<TextareaAutosize
								rowsMax={4}
								aria-label="nota"
								placeholder={note ? note : "..."}
								autoComplete="true"
								rowsMin={4}
								className={classes.textArea}
								onChange={handleChangeNote}
							/>
						</FormControl>
					</div>
				</Dialog>
			</Container>
		);
	}

	return (
		<Container classes={{ root: classes.progress }}>
			<CircularProgress />
		</Container>
	);
}
