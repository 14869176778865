import React, { useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import MuiLink from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { useHistory } from "react-router-dom";
import ForgotPassword from "./ForgotPassword";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useAuth } from "../../context/Auth";
import { useToast } from "../../context/Toast";


const useStyles = makeStyles(theme => ({
	paper: {
		marginTop: theme.spacing(8),
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: "100%", // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	link: {
		cursor: "pointer",
	},
	progressContainer: {
		width: "100%",
		height: "100vh",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
}));

export default function SignIn(props) {
	const history = useHistory();
	const classes = useStyles();
	const auth = useAuth();
	const { fireToast } = useToast();
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [showPasswordReset, setShowPasswordReset] = useState(false);
	const [showProgress, setShowProgress] = useState(false);

	const logUser = async event => {
		event.preventDefault();
		setShowProgress(true);
		try {
			await auth.login(username, password);
			fireToast("Login effettuato con successo");
			setShowProgress(false);
		} catch (err) {
			fireToast(
				"Errore durante il login. Verificare le proprie credenziali, quindi riprovare.",
				null,
				"error"
			);
			setShowProgress(false);
		}
		history.push("/");
	};

	if (showProgress) {
		return (
			<Container classes={{ root: classes.progressContainer }} maxWidth="xl">
				<CircularProgress />
			</Container>
		);
	}

	return (
		<div className={classes.root}>
			<CssBaseline />
			<AppBar position="fixed">
				<Toolbar>
					<Typography variant="h6" noWrap>
						Comune di {process.env.REACT_APP_NOME_COMUNE}
					</Typography>
				</Toolbar>
			</AppBar>
			<Container style={{ marginTop: 150 }} component="main" maxWidth="xs">
				<div className={classes.paper}>
					{showPasswordReset ? (
						<ForgotPassword fireBackClick={() => setShowPasswordReset(false)} />
					) : (
						<>
							<Avatar className={classes.avatar}>
								<LockOutlinedIcon />
							</Avatar>
							<Typography component="h1" variant="h5">
								PAGINA ACCESSO UTENTE
							</Typography>
							<form className={classes.form} noValidate onSubmit={logUser}>
								<TextField
									variant="outlined"
									margin="normal"
									required
									fullWidth
									id="email"
									label="Username o email"
									name="email"
									autoComplete="email"
									autoFocus
									onChange={event => setUsername(event.target.value)}
								/>
								<TextField
									variant="outlined"
									margin="normal"
									required
									fullWidth
									name="password"
									label="Password"
									type="password"
									id="password"
									autoComplete="current-password"
									onChange={event => setPassword(event.target.value)}
								/>
								<Button
									type="submit"
									fullWidth
									variant="contained"
									color="primary"
									className={classes.submit}
								>
									Accedi
								</Button>
								<Grid container>
									<Grid item xs>
										<MuiLink
											variant="body2"
											onClick={() => setShowPasswordReset(true)}
										>
											<span className={classes.link}>
												Password dimenticata?
											</span>
										</MuiLink>
									</Grid>
								</Grid>
							</form>
						</>
					)}
				</div>
				<Box mt={8}>
					{/*	<Copyright />*/}
				</Box>
			</Container>
		</div>
	);
}
