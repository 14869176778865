import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Container } from "@material-ui/core";
import { useSelector } from "react-redux";
import {ButtonGroup} from "@material-ui/core";
import HelpdeskCittadini from "./cittadini";
import HelpdeskUtenti from "./utenti";

const useStyles = makeStyles(theme => ({
    title: {
        margin: 0,
    },
    tableContainer: {
        padding: "20px 0",
    },
    progressContainer: {
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
}));


export default function HelpdeskIndex() {
    const classes = useStyles();
    const permissions = useSelector(state => state.permissions);
    const [userTypeSwitch, setUserTypeSwitch] = useState("cittadini");
    if (permissions) {
        return (
            <Container maxWidth="xl">
                <ButtonGroup size="large" aria-label="medium button group">
                    <button onClick={e => setUserTypeSwitch("cittadini")}>cittadini</button>
                    <button onClick={e => setUserTypeSwitch("utenti")}>operatori</button>
                </ButtonGroup>
                {
                    userTypeSwitch === "cittadini" ?
                        <HelpdeskCittadini/>
                        :
                        <HelpdeskUtenti/>

                }
            </Container>
        );
    }

    return (
        <Container classes={{ root: classes.progressContainer }} maxWidth="xl">
            <CircularProgress />
        </Container>
    );
}
