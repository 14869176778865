import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
	getTicket,
	getAttachment,
} from "../../../services/ComunitaEnergetica";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "../../../components/Dialog";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Carousel from "../../../components/Carousel";
import moment from "moment";

const useStyles = makeStyles(theme => ({
	progress: {
		width: "100%",
		height: "100%",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	header: {
		display: "flex",
		justifyContent: "space-between",
	},
	actionsContainer: {
		display: "flex",
	},
	actionIcons: {
		padding: "10px",
		borderRadius: "50%",
		background: "#fff",
		marginRight: 10,
		display: "flex",
		boxShadow: "0px 0px 3px 0px rgba(0,0,0,0.15)",
		cursor: "pointer",
	},
	hover: {
		background: "#fff",
		"&:hover": {
			background: "#ccc",
		},
	},
	actionsTitle: {
		marginBottom: 5,
	},
	category: {
		marginLeft: "10px",
		padding: "5px",
		color: "#fff",
		backgroundColor: "orange",
		borderRadius: "5px",
		width: "contain",
	},
	subCategory: {
		marginLeft: "10px",
		padding: "5px",
		color: "#fff",
		backgroundColor: "green",
		borderRadius: "5px",
		width: "contain",
	},
	title: {
		display: "flex",
		alignItems: "center",
	},
	carousel: {
		width: "100%",
		display: "flex",
	},
	image: {
		height: "400px",
		width: "400px",
		backgroundColor: "#ccc",
		margin: "10px 10px",
	},
	state: {
		padding: "10px 0",
		fontSize: "15px",
	},
	disabled: {
		background: "#999",
		pointerEvents: "none",
		opacity: 0.5,
	},
	backIcon: {
		textDecoration: "none",
		color: theme.palette.grey[800],
	},
	dialogContainer: {
		display: "flex",
		flexDirection: "column",
	},
	formControl: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(2),
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
		minWidth: 300,
	},
	selectTitle: {
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
	},
	textArea: {
		marginTop: theme.spacing(1),
		padding: theme.spacing(2),
		minWidth: 500,
	},
	descriptionContainer: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
}));

export default function Dettaglio() {
	const [ticket, setTicket] = useState();
	const [attachments, setAttachments] = useState();
	const [open, setOpen] = useState(false);
	const [closureState, setClosureState] = useState(4);
	const [note, setNote] = useState("");
	const [building, setBuilding] = useState({});

	const classes = useStyles();
	let { id } = useParams();
	useEffect(() => {
		const getTicketById = async () => {
			const { data } = await getTicket(id);
			console.log("Comunita energetica DETTAGLIO", data);
			setBuilding(data.immobili);
			const attachments = await Promise.all(
				/*
				data.immobili.map(attachment =>
					//console.log('attachment',attachment.fotoBolletta)
					getAttachment(attachment.fotoBolletta)
				)
				*/
				data.immobili.map(
					attachment => getAttachment(attachment.fotoBolletta)
					//getAttachment(attachment.fotoVisuraCatastale)
				)
			);
			const attachmentsVisure = await Promise.all(
				data.immobili.map(attachment =>
					getAttachment(attachment.fotoVisuraCatastale)
				)
			);
			setTicket(data);

			var allAttachments = [...attachments, ...attachmentsVisure];

			setAttachments(
				allAttachments.map(img => {
					const obj = {
						id: Math.random().toString(36),
						url: URL.createObjectURL(img.data),
					};
					return obj;
				})
			);
		};
		getTicketById();
	}, [id]);

	const handleCloseDialog = () => setOpen(false);
	// const handleOpenDialog = () => setOpen(true);
	const handleChangeClosureState = event => setClosureState(event.target.value);
	const handleChangeNote = event => setNote(event.target.value);
	const handleSave = async () => {
		setOpen(false);
	};

	if (ticket && attachments) {
		const { anagrafica, isPersonaGiuridica } = ticket;
		return (
			<Container maxWidth="xl">
				<Link to="/comunita-energetica" className={classes.backIcon}>
					<ArrowBackIcon />
				</Link>
				<div className={classes.header}>
					<div>
						<div className={classes.title}>
							{/* <h1>Comunità energetica:{_id}</h1> */}
						</div>
						<div className={classes.state}>
							<span>
								{/*<strong>STATO:</strong> {getStateFromBaseValue(state)}*/}
							</span>
						</div>
					</div>
					{/*<div>
						<div className={classes.actionsTitle}>
							<strong>Azioni</strong>
						</div>
						<div className={classes.actionsContainer}>
							<div
								className={classes.actionIcons}
								style={
									getStateFromBaseValue(state) === "IN LAVORAZIONE" ||
									state >= 4 // TODO: Aggiungere anche caso in cui la segnalazione sia chiusa
										? {
												background: "#999",
												pointerEvents: "none",
												opacity: 0.5,
										  }
										: {
												background: "#fff",
										  }
								}
							>
								<Tooltip
									title="IN LAVORAZIONE"
									onClick={() => aggiornaStato(3)}
								>
									<Cached />
								</Tooltip>
							</div>
						</div>
					</div>*/}
				</div>
				<div className={classes.descriptionContainer}>
					<h2>
						Dati Richiedente{" "}
						<span>
							{" "}
							{isPersonaGiuridica === false
								? "(Persona fisica)"
								: "(Persona giuridica)"}{" "}
						</span>
					</h2>
					<p>
						<span style={{ fontWeight: "bold" }}>
							{isPersonaGiuridica === false
								? "Cognome e Nome:"
								: "Ragione Sociale:"}
						</span>{" "}
						{isPersonaGiuridica === false
							? anagrafica
								? anagrafica.cognome
								: " " + anagrafica
								? anagrafica.nome
								: ""
							: anagrafica
							? anagrafica.ragioneSociale
							: ""}
					</p>
					<p>
						<span style={{ fontWeight: "bold" }}>
							{isPersonaGiuridica === false
								? "Luogo e data di nascita: "
								: "Inizio attività: "}
						</span>{" "}
						{isPersonaGiuridica === false
							? anagrafica
								? anagrafica.luogoDiNascita
								: ""
							: ""}
						{}{" "}
						{anagrafica
							? moment(anagrafica.dataDiNascita).format("DD/MM/YYYY")
							: ""}
					</p>
					<p>
						<span style={{ fontWeight: "bold" }}>
							{isPersonaGiuridica === false
								? "Codice Fiscale:"
								: "Partita Iva:"}
						</span>{" "}
						{isPersonaGiuridica === false
							? anagrafica
								? anagrafica.cf
								: ""
							: anagrafica
							? anagrafica.piva
							: ""}
					</p>
					<p>
						<span style={{ fontWeight: "bold" }}>
							{isPersonaGiuridica === false ? "Residenza: " : "Sede Legale:"}
						</span>{" "}
						{anagrafica ? anagrafica.residenza : ""}
					</p>
					<p>
						<span style={{ fontWeight: "bold" }}>Telefono: </span>{" "}
						{anagrafica ? anagrafica.telefono : ""}
					</p>
					<p>
						<span style={{ fontWeight: "bold" }}>Email: </span>{" "}
						{anagrafica ? anagrafica.email : ""}
					</p>
					<p>
						<span style={{ fontWeight: "bold" }}>
							Tipo di uso dell'immobile:{" "}
						</span>{" "}
						{anagrafica ? anagrafica.usoImmobile : ""}
					</p>
					<p>
						<span style={{ fontWeight: "bold" }}>Note: </span>{" "}
						{anagrafica ? anagrafica.note : ""}
					</p>
				</div>

				<div style={{ marginTop: 50 }} className={classes.descriptionContainer}>
					<h2>Immobili</h2>
					<div className={classes.carousel}>
						{building && building.length ? (
							building.map((build, index) => (
								<div key={index}>
									<h3>Immobile n. {index + 1}</h3>
									<p>
										<li>
											<span style={{ fontWeight: "bold" }}>Indirizzo: </span>{" "}
											{build.indirizzo ? build.indirizzo : ""}
										</li>
									</p>
									<p>
										<li>
											<span style={{ fontWeight: "bold" }}>Numero POD: </span>{" "}
											{build.codPod ? build.codPod : ""}
										</li>
									</p>
									<p>
										<li>
											<span style={{ fontWeight: "bold" }}>
												Coordinate GPS:{" "}
											</span>{" "}
											{build.posizione
												? build.posizione[0] + ", " + build.posizione[1]
												: ""}
										</li>
									</p>
								</div>
							))
						) : (
							<strong style={{ marginTop: 20, marginBottom: 20 }}>
								Nessun immobile.
							</strong>
						)}
					</div>
				</div>

				<div style={{ marginTop: 50 }} className={classes.descriptionContainer}>
					<h2>Allegati</h2>
					<div className={classes.carousel}>
						{attachments && attachments.length ? (
							<Carousel data={attachments} />
						) : (
							<strong style={{ marginTop: 20, marginBottom: 20 }}>
								Nessun allegato.
							</strong>
						)}
					</div>
				</div>
				<Dialog
					open={open}
					onClose={handleCloseDialog}
					onSave={handleSave}
					title="Chiusura richiesta"
					buttonTitle="Salva"
				>
					<div className={classes.dialogContainer}>
						<strong className={classes.selectTitle}>
							Selezionare esito chiusura.
						</strong>
						<FormControl className={classes.formControl}>
							<InputLabel id="closure-state">Stato</InputLabel>
							<Select
								labelId="closure-state"
								id="closure"
								value={closureState}
								onChange={handleChangeClosureState}
							>
								<MenuItem value={4}>SUCCESSO</MenuItem>
								<MenuItem value={5}>INSUCCESSO</MenuItem>
								<MenuItem value={7}>GIA' RIPARATO</MenuItem>
								<MenuItem value={8}>NON DI COMPETENZA</MenuItem>
							</Select>
						</FormControl>
						<FormControl className={classes.formControl}>
							<strong>Nota (opzionale)</strong>
							<TextareaAutosize
								rowsMax={4}
								aria-label="nota"
								placeholder={note ? note : "..."}
								autoComplete="true"
								rowsMin={4}
								className={classes.textArea}
								onChange={handleChangeNote}
							/>
						</FormControl>
					</div>
				</Dialog>
			</Container>
		);
	}

	return (
		<Container classes={{ root: classes.progress }}>
			<CircularProgress />
		</Container>
	);
}
