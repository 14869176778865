import { removeSession } from "react-session-persist/lib";
import { refresh, getPermissions } from "../../services/Auth";

export const refreshSession = () => {
	return async dispatch => {
		await refresh();
		dispatch({ type: "REFRESH" });
	};
};

export const registerPermissions = () => {
	return async dispatch => {
		try {
			const { data } = await getPermissions();
			dispatch({ type: "PERMISSIONS", permissions: data });
		} catch (err) {
			console.error(err);
		}
	};
};

export const resetSession = () => {
	return async dispatch => {
		try {
			await removeSession();
			dispatch({ type: "LOGOUT" });
		} catch (err) {
			console.error(err);
		}
	};
};
