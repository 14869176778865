import React from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";
import Drawer from "@material-ui/core/Drawer";
import Tooltip from "@material-ui/core/Tooltip";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import {
	Warning,
	Group,
	AccountBalance,
	SupervisedUserCircle,
	EmojiTransportation,
	Settings,
	Home,
	Assignment,
	HowToReg,
	AccountCircle,
	CalendarToday,
} from "@material-ui/icons";
import { Switch, Route, Link } from "react-router-dom";
import Segnalazioni from "../../pages/Segnalazioni/";
import DettaglioSegnalazioni from "../../pages/Segnalazioni/Dettaglio/Dettaglio";
import Baratto from "../../pages/Baratto/";
import DettaglioBaratto from "../../pages/Baratto/Dettaglio/Dettaglio";
import Articoli from "../../pages/Articoli";
import Editor from "../../pages/Articoli/NuovoArticolo";
import EditorBaratto from "../../pages/Baratto/NuovoProgetto";
import ComunitaEnergetica from "../../pages/ComunitaEnergetica";
import Profilo from "../../pages/Profilo";
import DettaglioComunitaEnergetica from "../../pages/ComunitaEnergetica/Dettaglio/Dettaglio";
import { useSelector, useDispatch } from "react-redux";
import { resetSession } from "../../store/actions/auth";
import Appuntamenti from "../../pages/Appuntamenti";
import { ToastProvider } from "../../context/Toast";
import Helpdesk from "../../pages/Helpdesk";
import DettaglioCittadino from "../../pages/Helpdesk/cittadini/dettaglio";
import DettaglioUtente from "../../pages/Helpdesk/utenti/dettaglio"

const drawerWidth = 260;

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	appBarShift: {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	menuButton: {
		marginRight: 36,
	},
	hide: {
		display: "none",
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: "nowrap",
	},
	drawerOpen: {
		width: drawerWidth,
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerClose: {
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		overflowX: "hidden",
		width: theme.spacing(7) + 1,
		[theme.breakpoints.up("sm")]: {
			width: theme.spacing(7) + 1,
		},
	},
	toolbar: {
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-end",
		padding: theme.spacing(0, 1),
		// necessary for content to be below app bar
		...theme.mixins.toolbar,
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
		overflowX: "hidden",
		height: "100vh",
	},
	listItem: {
		color: theme.palette.grey[800],
		textDecoration: "none",
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
	},
	progress: {
		width: "100%",
		height: "100vh",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	logout: {
		cursor: "pointer",
	},
	list: {
		padding: 0,
		"& hr:first-child": {
			border: "none",
		},
		"& hr": {
			borderBottom: `1px solid ${theme.palette.grey[300]}`,
		},
	},
	bottomList: {
		padding: 0,
	},
	divider: {
		background: "transparent",
		padding: "3px",
	},
	top: {
		borderTop: `1px solid ${theme.palette.grey[300]}`,
	},
	bottom: {
		borderBottom: `1px solid ${theme.palette.grey[300]}`,
	},
}));

export default function MiniDrawer() {
	const classes = useStyles();
	const theme = useTheme();
	const dispatch = useDispatch();
	const permissions = useSelector(state => state.permissions);
	const [open, setOpen] = React.useState(false);

	const handleDrawerOpen = () => {
		setOpen(true);
	};

	const handleDrawerClose = () => {
		setOpen(false);
	};

	const renderIcons = text => {
		switch (text) {
			case "Home":
				return <Home />;
			case "Segnalazioni":
				return <Warning />;
			case "Partenariato sociale":
				return <Group />;
			case "Tributi":
				return <AccountBalance />;
			case "Servizi amministrativi":
				return <SupervisedUserCircle />;
			case "Comunità energetica":
				return <EmojiTransportation />;
			case "Articoli":
				return <Assignment />;
			case "Impostazioni":
				return <Settings />;
			case "Profilo":
				return <AccountCircle />;
			case "Appuntamenti":
				return <CalendarToday />;
			case "admin":
				return <HowToReg />;
			default:
				return;
		}
	};

	const renderPages = title => {
		switch (title) {
			case "Segnalazioni":
				return "/segnalazioni";
			case "Comunità energetica":
				return "/comunita-energetica";
			case "Partenariato sociale":
				return "/partenariato";
			case "Articoli":
				return "/articoli";
			case "Impostazioni":
				return;
			case "Profilo":
				return "/profilo";
			case "Appuntamenti":
				return "/appuntamenti";
			case "admin":
				return "/helpdesk";
			default:
				return "/";
		}
	};

	const hasPermission = section => {
		const availablePermissions = permissions.map(
			permission => permission.sezione
		);
		switch (section) {
			case "Segnalazioni":
				return availablePermissions.includes("segnalazioni");
			case "Comunità energetica":
				return availablePermissions.includes("comunitaenergetica");
			case "Articoli":
				return availablePermissions.includes("articoli");
			case "Partenariato sociale":
				return availablePermissions.includes("barattosociale");
			case "Appuntamenti":
				return availablePermissions.includes("appuntamenti");
			case "admin":
				return availablePermissions.includes("admin");
			default:
				return false;
		}
	};

	if (permissions) {
		return (
			<ToastProvider>
				<div className={classes.root}>
					<AppBar
						position="fixed"
						className={clsx(classes.appBar, {
							[classes.appBarShift]: open,
						})}
					>
						<Toolbar>
							<div
								style={{
									display: "flex",
									justifyContent: "space-between",
									alignItems: "center",
									flexWrap: "wrap",
									width: "100%",
								}}
							>
								<div
									style={{
										display: "flex",
										alignItems: "center",
										flexWrap: "wrap",
									}}
								>
									<IconButton
										color="inherit"
										aria-label="open drawer"
										onClick={handleDrawerOpen}
										edge="start"
										className={clsx(classes.menuButton, {
											[classes.hide]: open,
										})}
									>
										<MenuIcon />
									</IconButton>
									<Typography variant="h6" noWrap>
										Comune di {process.env.REACT_APP_NOME_COMUNE}
									</Typography>
								</div>
								<div>
									<Typography
										component="span"
										variant="button"
										classes={{ root: classes.logout }}
										onClick={() => dispatch(resetSession())}
									>
										Esci
									</Typography>
								</div>
							</div>
						</Toolbar>
					</AppBar>
					<Drawer
						variant="permanent"
						className={clsx(classes.drawer, {
							[classes.drawerOpen]: open,
							[classes.drawerClose]: !open,
						})}
						classes={{
							paper: clsx({
								[classes.drawerOpen]: open,
								[classes.drawerClose]: !open,
							}),
						}}
					// onMouseOver={handleDrawerOpen}
					// onMouseOut={handleDrawerClose}
					>
						<div style={{ height: "90%" }}>
							<div className={classes.toolbar}>
								<IconButton onClick={handleDrawerClose}>
									{theme.direction === "rtl" ? (
										<ChevronRightIcon />
									) : (
										<ChevronLeftIcon />
									)}
								</IconButton>
							</div>
							<List classes={{ root: classes.list }}>
								{["Home"].map((text, index) => (
									<div key={text}>
										<Divider classes={{ root: classes.divider }} />
										<Link to="/" className={classes.listItem}>
											<Tooltip title={text} aria-label={text} placement="right">
												<ListItem button>
													<ListItemIcon>{renderIcons(text)}</ListItemIcon>
													<ListItemText
														classes={{ primary: classes.listItem.primary }}
														primary={text}
													/>
												</ListItem>
											</Tooltip>
										</Link>
										<Divider classes={{ root: classes.divider }} />
									</div>
								))}
							</List>

							<List classes={{ root: classes.list }}>
								{[
									"Segnalazioni",
									"Tributi",
									"Servizi amministrativi",
									"Comunità energetica"
								]
									.filter(section => hasPermission(section))
									.map((text, index) => (
										<div key={text}>
											<Divider classes={{ root: classes.divider }} />
											<Link to={renderPages(text)} className={classes.listItem}>
												<Tooltip
													title={text}
													aria-label={text}
													placement="right"
												>
													<ListItem button>
														<ListItemIcon>{renderIcons(text)}</ListItemIcon>
														<ListItemText
															classes={{ primary: classes.listItem.primary }}
															primary={text}
														/>
													</ListItem>
												</Tooltip>
											</Link>
											<Divider classes={{ root: classes.divider }} />
										</div>
									))}
							</List>

							<List classes={{ root: classes.list }}>
								{["Partenariato sociale"]
									.filter(section => hasPermission(section))
									.map((text, index) => (
										<div key={text}>
											<Divider classes={{ root: classes.divider }} />
											<Link to={renderPages(text)} className={classes.listItem}>
												<Tooltip
													title={text}
													aria-label={text}
													placement="right"
												>
													<ListItem button>
														<ListItemIcon>{renderIcons(text)}</ListItemIcon>
														<ListItemText
															classes={{ primary: classes.listItem.primary }}
															primary={text}
														/>
													</ListItem>
												</Tooltip>
											</Link>
											<Divider classes={{ root: classes.divider }} />
										</div>
									))}
							</List>

							<List classes={{ root: classes.list }}>
								{["Articoli"]
									.filter(section => hasPermission(section))
									.map((text, index) => (
										<div key={text}>
											<Divider classes={{ root: classes.divider }} />
											<Link to={renderPages(text)} className={classes.listItem}>
												<Tooltip
													title={text}
													aria-label={text}
													placement="right"
												>
													<ListItem button>
														<ListItemIcon>{renderIcons(text)}</ListItemIcon>
														<ListItemText
															classes={{ primary: classes.listItem.primary }}
															primary={text}
														/>
													</ListItem>
												</Tooltip>
											</Link>
											<Divider classes={{ root: classes.divider }} />
										</div>
									))}
							</List>

							<List classes={{ root: classes.list }}>
								{["Appuntamenti"]
									.filter(section => hasPermission(section))
									.map((text, index) => (
										<div key={text}>
											<Divider classes={{ root: classes.divider }} />
											<Link to={renderPages(text)} className={classes.listItem}>
												<Tooltip
													title={text}
													aria-label={text}
													placement="right"
												>
													<ListItem button>
														<ListItemIcon>{renderIcons(text)}</ListItemIcon>
														<ListItemText
															classes={{ primary: classes.listItem.primary }}
															primary={text}
														/>
													</ListItem>
												</Tooltip>
											</Link>
											<Divider classes={{ root: classes.divider }} />
										</div>
									))}
							</List>
							<List classes={{ root: classes.list }}>
								{["admin"]
									.filter(section => hasPermission(section))
									.map((text, index) => (
										<div key={text}>
											<Divider classes={{ root: classes.divider }} />
											<Link to={renderPages(text)} className={classes.listItem}>
												<Tooltip
													title={text}
													aria-label={text}
													placement="right"
												>
													<ListItem button>
														<ListItemIcon>{renderIcons(text)}</ListItemIcon>
														<ListItemText
															classes={{ primary: classes.listItem.primary }}
															primary={text}
														/>
													</ListItem>
												</Tooltip>
											</Link>
											<Divider classes={{ root: classes.divider }} />
										</div>
									))}
							</List>
						</div>
						<div>
							<List classes={{ root: classes.bottomList }}>
								{["Profilo"].map((text, index) => (
									<div key={text}>
										{index === 0 ? (
											<Divider
												classes={{ root: classes.divider }}
												className={classes.top}
											/>
										) : (
											<Divider classes={{ root: classes.divider }} />
										)}

										<Link to="/profilo" className={classes.listItem}>
											<Tooltip title={text} aria-label={text} placement="right">
												<ListItem button key={text}>
													<ListItemIcon>{renderIcons(text)}</ListItemIcon>
													<ListItemText primary={text} />
												</ListItem>
											</Tooltip>
										</Link>
										<Divider
											classes={{ root: classes.divider }}
											className={classes.bottom}
										/>
									</div>
								))}
							</List>
							{/*<List classes={{ root: classes.bottomList }}>
								{["Impostazioni"].map((text, index) => (
									<div key={text}>
										{index === 0 ? (
											<Divider
												classes={{ root: classes.divider }}
												className={classes.top}
											/>
										) : (
											<Divider classes={{ root: classes.divider }} />
										)}

										<Link to="/" className={classes.listItem}>
											<Tooltip title={text} aria-label={text} placement="right">
												<ListItem button key={text}>
													<ListItemIcon>{renderIcons(text)}</ListItemIcon>
													<ListItemText primary={text} />
												</ListItem>
											</Tooltip>
										</Link>
										<Divider
											classes={{ root: classes.divider }}
											className={classes.bottom}
										/>
									</div>
								))}
							</List>*/}
						</div>
					</Drawer>
					<main className={classes.content}>
						<div className={classes.toolbar} />
						<Switch>
							<Route exact path="/">
								<div>
									<p>
										Benvenuto nel portale amministrativo , puoi eseguire le
										operazioni dal menu a sinistra
									</p>
								</div>
							</Route>
							<Route exact path="/segnalazioni">
								<Segnalazioni />
							</Route>
							<Route
								path="/segnalazioni/:id"
								children={<DettaglioSegnalazioni />}
							/>
							<Route exact path="/partenariato">
								<Baratto />
							</Route>
							<Route path="/partenariato/:id" children={<DettaglioBaratto />} />
							<Route exact path="/progetto/nuovo">
								<EditorBaratto />
							</Route>
							<Route exact path="/comunita-energetica">
								<ComunitaEnergetica />
							</Route>
							<Route
								path="/comunita-energetica/:id"
								children={<DettaglioComunitaEnergetica />}
							/>
							<Route exact path="/articoli">
								<Articoli />
							</Route>
							<Route path="/articoli/nuovo">
								<Editor />
							</Route>
							<Route path="/profilo">
								<Profilo />
							</Route>
							<Route path="/articoli/:id">
								<Editor />
							</Route>
							<Route exact path="/appuntamenti">
								<Appuntamenti />
							</Route>
							<Route exact path="/helpdesk">
								<Helpdesk />
							</Route>
							<Route exact path="/helpdesk/cittadini/:id">
								<DettaglioCittadino userType="cittadino" />
							</Route>
							<Route exact path="/helpdesk/utenti/:id">
								<DettaglioCittadino userType="utente" />
							</Route>
						</Switch>
					</main>
				</div>
			</ToastProvider>
		);
	}

	return (
		<Container classes={{ root: classes.progress }}>
			<CircularProgress />
		</Container>
	);
}
